/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const adminRoutes = {
  path: '/admin',
  component: Layout,
  redirect: '/admin/users',
  name: 'Admin',
  alwaysShow: false,
  meta: {
    title: 'administrator',
    icon: 'admin',
  },
  children: [
    {
      path: 'departments',
      component: () => import('@/views/department/index'),
      name: 'Department',
      meta: {
        title: 'department',
        icon: 'department',
        noCache: true,
        permissions: ['manage department'],
      },
    },
    {
      path: 'positions',
      component: () => import('@/views/position/index'),
      name: 'Position',
      meta: {
        title: 'position',
        icon: 'position',
        noCache: true,
        permissions: ['manage position'],
      },
    },
    {
      path: 'users',
      component: () => import('@/views/users/List'),
      name: 'UserList',
      meta: {
        title: 'users',
        icon: 'user-sidebar',
        permissions: ['manage user'],
      },
      props: true,
    },
    {
      path: 'users/create',
      component: () => import('@/views/users/Edit'),
      name: 'CreateUser',
      meta: {
        title: 'createUser',
        icon: 'user',
        permissions: ['manage user'],
      },
      props: true,
      hidden: true,
    },
    {
      path: 'users/edit/:id',
      component: () => import('@/views/users/Edit'),
      name: 'EditUser',
      meta: { title: 'editUser', icon: 'user', permissions: ['manage user'] },
      props: true,
      hidden: true,
    },
    {
      path: 'roles',
      component: () => import('@/views/role-permission/List'),
      name: 'RoleList',
      meta: {
        title: 'role',
        icon: 'role',
        permissions: ['manage role'],
      },
    },
    {
      path: 'permissions',
      component: () => import('@/views/permission/index'),
      name: 'Permission',
      hidden: true,
      meta: {
        title: 'permission',
        icon: 'permission',
        permissions: ['manage permission'],
      },
    },
    {
      path: 'skills',
      component: () => import('@/views/skills/index'),
      name: 'Skill',
      meta: {
        title: 'skill',
        icon: 'skill',
        permissions: ['manage skill'],
      },
    },
    {
      path: 'skill-sheets',
      component: () => import('@/views/skill-sheets/index'),
      name: 'skill-sheets',
      meta: {
        title: 'skillSheet',
        icon: 'skill',
        permissions: ['manage skill sheet'],
      },
    },
    {
      path: 'property',
      component: () => import('@/views/property/index'),
      name: 'property',
      meta: {
        title: 'property',
        icon: 'property',
        permissions: ['manage property'],
      },
    },
    {
      path: 'cv',
      component: () => import('@/views/cv/index'),
      name: 'cv',
      meta: {
        title: 'CV',
        icon: 'cv',
        permissions: ['manage cv'],
      },
    },
    {
      path: 'contracts',
      component: () => import('@/views/contract/index'),
      name: 'ContractList',
      meta: {
        title: 'contract',
        icon: 'contract',
        permissions: ['manage contract'],
      },
    },
    {
      path: 'contracts/edit/:id',
      component: () => import('@/views/contract/edit'),
      name: 'EditContract',
      meta: {
        title: 'editContract',
        icon: 'contract',
        permissions: ['manage contract'],
      },
      props: true,
      hidden: true,
    },
    {
      path: 'contracts/create',
      component: () => import('@/views/contract/edit'),
      name: 'CreateContract',
      meta: {
        title: 'createContract',
        icon: 'contract',
        permissions: ['manage contract'],
      },
      props: true,
      hidden: true,
    },
    {
      path: 'teams',
      component: () => import('@/views/teams/index'),
      name: 'team',
      meta: {
        title: 'team',
        icon: 'team',
        permissions: ['manage team'],
      },
    },
    {
      path: 'banks',
      component: () => import('@/views/banks/index'),
      name: 'bank',
      hidden: true,
      meta: {
        title: 'Bank',
        icon: 'bank',
        permissions: ['manage bank'],
      },
    },
    {
      path: 'bank-users',
      component: () => import('@/views/bank-users/index'),
      name: 'bank-users',
      meta: {
        title: 'bankUser',
        icon: 'bankUser',
        permissions: ['manage bank'],
      },
    },
    {
      path: 'charge-jobs',
      component: () => import('@/views/charge-jobs/index'),
      name: 'ChargeJobs',
      hidden: true,
      meta: {
        title: 'chargeJobs',
        icon: 'charge-jobs',
        permissions: ['manage project'],
      },
    },
    {
      path: 'projects',
      component: () => import('@/views/project/index'),
      name: 'Project',
      meta: {
        title: 'project',
        icon: 'project',
        permissions: ['manage project'],
      },
    },
    {
      path: 'notifications',
      component: () => import('@/views/notification/index'),
      name: 'Notification',
      meta: {
        title: 'notification',
        icon: 'notification',
        permissions: ['manage notification'],
      },
    },
  ],
};

export default adminRoutes;
