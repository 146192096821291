const getters = {
  sidebar: (state) => state.app.sidebar,
  language: (state) => state.app.language,
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  userId: (state) => state.UserStore.id,
  token: (state) => state.UserStore.token,
  avatar: (state) => state.UserStore.avatar,
  name: (state) => state.UserStore.name,
  user: (state) => state.UserStore.user,
  introduction: (state) => state.UserStore.introduction,
  roles: (state) => state.UserStore.roles,
  permissions: (state) => state.UserStore.permissions,
  permissionRoutes: (state) => state.permission.routes,
  addRoutes: (state) => state.permission.addRoutes,
  userMeta: (state) => state.UserStore.userMeta,
  usersExpirationContract: (state) => state.UserStore.usersExpirationContract,
  usersBirthdayInMonth: (state) => state.UserStore.usersBirthdayInMonth,
  usersHasAnniCurrentMonth: (state) => state.UserStore.usersHasAnniCurrentMonth,
  newUsers: (state) => state.UserStore.newUsers,
  usersExceptAdmin: (state) => state.UserStore.usersExceptAdmin,
  position: (state) => state.UserStore.position,
  department: (state) => state.UserStore.department,
  evaluates: (state) => state.UserStore.evaluates,
  notificationOfUser: (state) => state.NotificationStore.notificationOfUser,
  updateStatusNotificationOfUser: (state) =>
    state.NotificationStore.updateStatusNotificationOfUser,
  companyDirectory: (state) => state.UserStore.companyDirectory,
  eventResponse: (state) => state.EventStore.event_response,
};
export default getters;
