<template>
  <div>
    <div class="mt-5 flex justify-center mb-5">
      <div>
        <router-link to="/">
          <img :src="logo" alt="logo" />
        </router-link>
      </div>
    </div>

    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :show-timeout="200"
        :default-active="$route.path"
        :collapse="isCollapse"
        background-color="#ffff"
        text-color="#474747"
        active-text-color="#CD3131"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
import variables from '@/styles/variables.scss';
import logo from '@/assets/login/logo.webp';

export default {
  name: 'SidebarIndex',
  components: { SidebarItem },
  data() {
    return {
      logo: logo,
    };
  },
  computed: {
    ...mapGetters(['sidebar', 'permission_routers']),
    routes() {
      return this.$store.state.permission.routes;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      console.log(variables);
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
</style>
