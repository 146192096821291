import CurriculumVitaeApi from '@/api/CurriculumVitaeApi';
import BaseStore from './BaseStore';

const BaseCurriculumVitaeStore = new BaseStore(CurriculumVitaeApi);

const state = {
  curriculum_vitae_response: [],
};

const getters = {
  curriculumVitaeResponse: (state) => state.curriculum_vitae_response,
};

const mutations = {
  SET_CURRICULUM_VITAE_RESPONSE(state, payload) {
    state.curriculum_vitae_response = payload;
  },
};

const actions = {
  ...BaseCurriculumVitaeStore.actions,
  async fetchCurriculumVitaes({ commit }, listQuery) {
    const response = await CurriculumVitaeApi.get(listQuery);
    if (response) {
      commit('SET_CURRICULUM_VITAE_RESPONSE', response);
    }
  },
  async getSelfCV() {
    try {
      const response = await CurriculumVitaeApi.getSelfCV();
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async updateSelfCV(_, data) {
    const response = await CurriculumVitaeApi.updateSelfCV(data);
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
