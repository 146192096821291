import ContractApi from '@/api/ContractApi';
import BaseStore from './BaseStore';

const BaseContractStore = new BaseStore(ContractApi);

const state = {
  contract_response: [],
};

const getters = {
  contractResponse: (state) => state.contract_response,
};

const mutations = {
  SET_CONTRACT_RESPONSE(state, payload) {
    state.contract_response = payload;
  },
};

const actions = {
  ...BaseContractStore.actions,
  async fetchContracts({ commit }, listQuery) {
    const response = await ContractApi.get(listQuery);
    if (response) {
      commit('SET_CONTRACT_RESPONSE', response);
    }
  },

  async updateContract({ dispatch }, { id, data }) {
    const response = await ContractApi.update(id, data);
    return response;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
