import TeamApi from '@/api/TeamApi';
import UserApi from '@/api/UserApi';
import BaseStore from './BaseStore';

const BaseTeamStore = new BaseStore(TeamApi);

const state = {
  team_response: [],
};

const getters = {
  teamResponse: (state) => state.team_response,
};

const mutations = {
  SET_TEAM_RESPONSE(state, payload) {
    state.team_response = payload;
  },
};

const actions = {
  ...BaseTeamStore.actions,
  async fetchTeams(context, listQuery) {
    const response = await TeamApi.get(listQuery);

    if (response) {
      context.commit('SET_TEAM_RESPONSE', response);
    }
  },
  async fetchAvailableData() {
    const response = await UserApi.getUsersExceptAdmin();
    if (response) {
      return response;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
