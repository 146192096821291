<template>
  <div class="navbar">
    <hamburger
      v-if="device === 'mobile'"
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="h-full">
      <template v-if="device !== 'mobile'">
        <!-- <search id="header-search" class="right-menu-item" /> -->

        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->

        <!-- <el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->
      </template>

      <div
        class="flex items-center h-full justify-end pr-4 md:pr-[30px] gap-3 md:gap-6"
      >
        <el-dropdown trigger="click" :hide-on-click="false">
          <span class="notification-icon relative">
            <svg-icon icon-class="bell" />
            <span
              v-if="notificationCount"
              class="absolute w-1.5 h-1.5 right-0.5 rounded-full bg-red-500"
            />
          </span>
          <el-dropdown-menu
            slot="dropdown"
            v-infinite-scroll="loadMoreNotifications"
            class="dropdown-scrollable"
          >
            <div class="px-6 py-5 font-semibold text-lg">
              {{ $t('navbar.notification') }}
            </div>
            <div
              v-if="listNotification.filter((item) => item.status === 0).length"
              class="px-6 text-[15px] font-semibold mb-1"
            >
              News
            </div>
            <div
              v-for="notification in listNotification.filter(
                (item) => item.status === 0
              )"
              :key="notification.id"
            >
              <el-popover
                placement="left"
                :title="notification.title"
                width="300"
                trigger="hover"
                :content="notification.content"
              >
                <div
                  slot="reference"
                  class="px-6 py-3 w-[416px] cursor-pointer bg-[#fef7f3] hover:bg-[#fef7f3]"
                  @click="
                    handleUpdateStatus(notification.id, notification.status)
                  "
                >
                  <div class="text-sm text-[#CD3131] mb-1">
                    {{ notification.created_at }}
                  </div>
                  <div class="flex gap-4">
                    <div class="mt-3">
                      <div
                        class="w-8 h-8 bg-[#feeee4] flex items-center justify-center rounded-full"
                      >
                        <svg-icon icon-class="red-ring" />
                      </div>
                    </div>
                    <div class="full">
                      <div slot="reference">{{ notification.title }}</div>
                      <div class="text-sm max-h-[40px] overflow-hidden mt-1">
                        {{ notification.content }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-popover>
            </div>

            <div
              v-if="listNotification.filter((item) => item.status === 1).length"
              class="px-6 text-[15px] font-semibold mb-1 mt-4"
            >
              {{ $t('navbar.earlier') }}
            </div>
            <div
              v-for="notification in listNotification.filter(
                (item) => item.status === 1
              )"
              :key="notification.id"
            >
              <el-popover
                placement="left"
                :title="notification.title"
                width="300"
                trigger="hover"
                :content="notification.content"
              >
                <div
                  slot="reference"
                  class="px-6 py-3 w-[416px] cursor-pointer hover:bg-[#fef7f3]"
                  @click="
                    handleUpdateStatus(notification.id, notification.status)
                  "
                >
                  <div class="text-sm text-[#CD3131] mb-1">
                    {{ notification.created_at }}
                  </div>
                  <div class="flex gap-4">
                    <div class="mt-3">
                      <div
                        class="w-8 h-8 bg-[#feeee4] flex items-center justify-center rounded-full"
                      >
                        <svg-icon icon-class="red-ring" />
                      </div>
                    </div>
                    <div class="full">
                      <div slot="reference">{{ notification.title }}</div>
                      <div class="text-sm max-h-[40px] overflow-hidden mt-1">
                        {{ notification.content }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-popover>
            </div>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown
          class="avatar-container hover-effect"
          trigger="click"
          @command="handleCommand"
        >
          <div class="avatar-wrapper flex items-center h-full cursor-pointer">
            <el-avatar
              icon="el-icon-user-solid"
              size="medium"
              fit="cover"
              :src="avatar"
            />
          </div>
          <el-dropdown-menu slot="dropdown">
            <div class="w-[305px]">
              <div class="w-full bg-[#FFEEE4] h-[60px] relative">
                <div class="absolute w-full flex justify-center bottom-[-20px]">
                  <el-avatar
                    icon="el-icon-user-solid"
                    size="large"
                    fit="cover"
                    :src="avatar"
                  />
                </div>
              </div>

              <div class="text-center mt-8">
                <span class="font-semibold"> {{ user.name }}</span>
                <p class="text-sm text-[#474747]">{{ user.email }}</p>
              </div>
              <div class="px-[20px] pb-[20px] text-[#474747]">
                <el-divider />
                <router-link to="/staff/home">
                  <el-dropdown-item>
                    <svg-icon icon-class="group" />
                    {{ $t('navbar.dashboard') }}
                  </el-dropdown-item>
                </router-link>
                <router-link v-show="userId !== null" to="/staff/my-profile">
                  <el-dropdown-item>
                    <svg-icon icon-class="profile-square" />
                    {{ $t('navbar.profile') }}
                  </el-dropdown-item>
                </router-link>
                <el-dropdown-item command="password">
                  <svg-icon icon-class="lock-line" />
                  {{ $t('navbar.password') }}
                </el-dropdown-item>

                <el-divider />

                <el-dropdown-item divider>
                  <div @click="logout">
                    <svg-icon icon-class="logout" />
                    {{ $t('navbar.logOut') }}
                  </div>
                </el-dropdown-item>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <ChangPassword ref="changePassword" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
// import Screenfull from '@/components/Screenfull';
// import SizeSelect from '@/components/SizeSelect';
// import Search from '@/components/HeaderSearch';
import ChangPassword from '@/views/users/components/ChangePassword.vue';
export default {
  components: {
    Breadcrumb,
    Hamburger,
    ChangPassword,
  },
  data() {
    return {
      notificationCount: 0,
      listNotification: [],
      listQuery: {
        page: 1,
        limit: 10,
      },
    };
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'name',
      'avatar',
      'device',
      'userId',
      'user',
      'notificationOfUser',
      'updateStatusNotificationOfUser',
    ]),
  },
  async created() {
    this.getListNotification();
  },
  methods: {
    ...mapActions({
      authlogout: 'AuthStore/logout',
      getNotificationOfUser: 'NotificationStore/getNotificationOfUser',
      updateStatusNotification: 'NotificationStore/updateStatusNotification',
    }),

    handleCommand(command) {
      console.log(command);
      if (command === 'password') {
        this.$refs.changePassword.open();
      }
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.authlogout();
      this.$router.replace({
        path: '/',
        query: { noCache: true },
        force: true,
      });
      await this.$nextTick();
      this.$router.go(0);
    },
    async getListNotification() {
      await this.getNotificationOfUser(this.listQuery);
      this.listNotification = this.notificationOfUser.data.data;
      this.notificationCount = 0;
      this.listNotification.forEach((element) => {
        if (!element.status) {
          this.notificationCount++;
        }
      });
      // this.notificationCount = this.notificationOfUser.data.total;
    },
    async loadMoreNotifications() {
      if (this.listNotification.length >= this.notificationCount) {
        return;
      }

      this.listQuery.page++;
      await this.getNotificationOfUser(this.listQuery);
      this.listNotification = this.listNotification.concat(
        this.notificationOfUser.data.data
      );
    },
    async handleUpdateStatus(id, status) {
      if (status === 0) {
        await this.updateStatusNotification(id)
          .then((response) => {
            console.log(response);
            this.$message({
              type: 'success',
              message: this.$t('message.update_status_notification'),
            });
            this.getListNotification();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 68px;
  overflow: hidden;
  position: relative;
  background: #fff;

  .hamburger-container {
    display: flex;
    align-items: center;
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .notification-icon {
    cursor: pointer;
    .svg-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.truncate-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 300px;
}

.notification {
  word-break: break-word;
  width: 426px;
}

.dropdown-scrollable {
  max-height: 553px;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.dropdown-scrollable::-webkit-scrollbar {
  width: 0px;
}

.dropdown-scrollable::-webkit-scrollbar-track {
  background-color: none;
}

.dropdown-scrollable::-webkit-scrollbar-thumb {
  background-color: none;
  border-radius: 3px;
}

.custom-check-icon {
  font-size: 24px;
  color: green;
}

.notification-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-check > span {
  flex: 1;
}

.el-dropdown-menu {
  border-radius: 16px;
  padding: 0 !important;
  overflow: hidden;

  .el-dropdown-menu__item {
    border-radius: 6px;
    padding: 6px 8px;
    &:hover {
      background-color: #feeee4 !important;
      color: #606266;
    }
  }

  .el-divider--horizontal {
    margin: 16px 0px !important;
  }
}

:deep(.el-avatar > img) {
  max-width: 100%;
}
</style>
