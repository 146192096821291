export const USER_STATUS = {
  0: 'user.leaveOffWork',
  1: 'user.working',
};

export const TEAM_USER_TYPE = {
  LEADER: 1,
  MEMBER: 0,
};

export const MONTH = [
  {
    value: 1,
    name: 'month.january',
  },
  {
    value: 2,
    name: 'month.february',
  },
  {
    value: 3,
    name: 'month.march',
  },
  {
    value: 4,
    name: 'month.april',
  },
  {
    value: 5,
    name: 'month.may',
  },
  {
    value: 6,
    name: 'month.june',
  },
  {
    value: 7,
    name: 'month.july',
  },
  {
    value: 8,
    name: 'month.august',
  },
  {
    value: 9,
    name: 'month.september',
  },
  {
    value: 10,
    name: 'month.october',
  },
  {
    value: 11,
    name: 'month.november',
  },
  {
    value: 12,
    name: 'month.december',
  },
];

export const USER_GENDER = {
  0: 'user.gender_female',
  1: 'user.gender_male',
  2: 'user.gender_another',
};

export const MARITAL_STATUS = {
  1: 'user.marital_married',
  2: 'user.marital_widowed',
  3: 'user.marital_separated',
  4: 'user.marital_divorced',
  5: 'user.marital_single',
};

export const USER_MEDIA_SCAN_KEY = {
  avatar: 'avatar',
  scan_curriculum_vitae: 'scan_curriculum_vitae',
  scan_citizen_id: 'scan_citizen_id',
  scan_registration_book: 'scan_registration_book',
  scan_certificate: 'scan_certificate',
  scan_health_records: 'scan_health_records',
};
export const USER_MEDIA_SCAN_VALUE = {
  [USER_MEDIA_SCAN_KEY.avatar]: 1,
  [USER_MEDIA_SCAN_KEY.scan_curriculum_vitae]: 2,
  [USER_MEDIA_SCAN_KEY.scan_citizen_id]: 3,
  [USER_MEDIA_SCAN_KEY.scan_registration_book]: 4,
  [USER_MEDIA_SCAN_KEY.scan_certificate]: 5,
  [USER_MEDIA_SCAN_KEY.scan_health_records]: 6,
};
export const USER_MEDIA_SCAN_TYPE = {
  [USER_MEDIA_SCAN_KEY.avatar]: 'user.avatar',
  [USER_MEDIA_SCAN_KEY.scan_curriculum_vitae]: 'user.scan_curriculum_vitae',
  [USER_MEDIA_SCAN_KEY.scan_citizen_id]: 'user.scan_citizen_id',
  [USER_MEDIA_SCAN_KEY.scan_registration_book]: 'user.scan_registration_book',
  [USER_MEDIA_SCAN_KEY.scan_certificate]: 'user.scan_certificate',
  [USER_MEDIA_SCAN_KEY.scan_health_records]: 'user.scan_health_records',
};

export const TYPE_PROPERTY = [
  {
    value: 0,
    name: 'property.other',
  },
  {
    value: 1,
    name: 'property.computer_screen',
  },
  {
    value: 2,
    name: 'property.mouse',
  },
  {
    value: 3,
    name: 'property.keyboard',
  },
  {
    value: 4,
    name: 'property.computer_case',
  },
  {
    value: 5,
    name: 'property.laptop',
  },
];

export const CONTRACT_MEDIA_SCAN_KEY = {
  scan_contract: 'scan_contract',
  scan_addendums: 'scan_addendums',
  scan_resignation: 'scan_resignation',
  scan_acceptance_certificate: 'scan_acceptance_certificate',
};

export const CONTRACT_MEDIA_SCAN_VALUE = {
  [CONTRACT_MEDIA_SCAN_KEY.scan_contract]: 1,
  [CONTRACT_MEDIA_SCAN_KEY.scan_addendums]: 2,
  [CONTRACT_MEDIA_SCAN_KEY.scan_resignation]: 3,
  [CONTRACT_MEDIA_SCAN_KEY.scan_acceptance_certificate]: 4,
};

export const CONTRACT_MEDIA_SCAN_TYPE = {
  [CONTRACT_MEDIA_SCAN_KEY.scan_contract]: 'contract.scan_contract',
  [CONTRACT_MEDIA_SCAN_KEY.scan_addendums]: 'contract.scan_addendums',
  [CONTRACT_MEDIA_SCAN_KEY.scan_resignation]: 'contract.scan_resignation',
  [CONTRACT_MEDIA_SCAN_KEY.scan_acceptance_certificate]:
    'contract.scan_acceptance_certificate',
};

export const MULTIPLE_SCANS = [CONTRACT_MEDIA_SCAN_KEY.scan_addendums];

export const RESIGNATION_SCAN_GROUP = [
  CONTRACT_MEDIA_SCAN_KEY.scan_resignation,
  CONTRACT_MEDIA_SCAN_KEY.scan_acceptance_certificate,
];

export const USER_STATUS_VALUE = {
  LEAVE_OFF_WORK: 0,
  WORKING: 1,
};

export const REQUIRED_SCAN_GROUP = [CONTRACT_MEDIA_SCAN_KEY.scan_contract];

export const CONTRACT_TYPE = [
  {
    name: 'contract.fixed_term_contract',
    value: 1,
  },
  {
    name: 'contract.indefinite_contract',
    value: 2,
  },
  {
    name: 'contract.probationary_contract',
    value: 3,
  },
];
export const LANGUAGE_TYPE = [
  {
    label: 'language.vi',
    name: 'vi',
    value: 1,
  },
  {
    label: 'language.en',
    name: 'en',
    value: 2,
  },
];
export const EMPLOYEE_TYPE = [
  {
    label: 'employee_type.freelance',
    value: 1,
  },
  {
    label: 'employee_type.contract',
    value: 2,
  },
];
export const INTERNAL_PROJECT = 1;
export const EXTERNAL_PROJECT = 2;
export const PROJECT_PROVIDED_TYPE = [
  {
    label: 'project_provided_type.internal',
    value: INTERNAL_PROJECT,
  },
  {
    label: 'project_provided_type.external',
    value: EXTERNAL_PROJECT,
  },
];
