export default {
  common: {
    back: 'Quay lại',
    return: 'Trở về',
  },
  route: {
    dashboard: 'Giao diện chính',
    introduction: 'Giới thiệu',
    documentation: 'Tài liệu',
    guide: 'Hướng dẫn',
    permission: 'Quyền truy cập',
    pagePermission: 'Trang giới hạn truy cập',
    directivePermission: 'Thuộc tính truy cập',
    icons: 'Ký hiệu',
    components: 'Thành phần',
    componentIndex: 'Giới thiệu',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'Soạn thảo JSON',
    dndList: 'Danh sách Dnd',
    splitPane: 'SplitPane',
    avatarUpload: 'Tải hình đại diện',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'CountTo',
    componentMixin: 'Mixin',
    backToTop: 'Quay về đầu trang',
    dragDialog: 'Drag Dialog',
    dragSelect: 'Drag Select',
    dragKanban: 'Drag Kanban',
    charts: 'Biểu đồ',
    keyboardChart: 'Biểu đồ bàn phím',
    lineChart: 'Biểu đồ đường',
    mixChart: 'Biểu đồ đa dạng',
    example: 'Ví dụ',
    nested: 'Danh mục con',
    menu1: 'Danh mục 1',
    'menu1-1': 'Danh mục 1-1',
    'menu1-2': 'Danh mục 1-2',
    'menu1-2-1': 'Danh mục 1-2-1',
    'menu1-2-2': 'Danh mục 1-2-2',
    'menu1-3': 'Danh mục 1-3',
    menu2: 'Danh mục 2',
    Table: 'Bảng',
    dynamicTable: 'Bảng động',
    dragTable: 'Bảng kéo thả',
    inlineEditTable: 'Chỉnh sửa trực tiếp',
    complexTable: 'Bảng phức tạp',
    treeTable: 'Bảng dạng cây',
    customTreeTable: 'Bảng dạng cây tùy biến',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Viết bài',
    editArticle: 'Chỉnh sửa',
    articleList: 'Tin tức',
    errorPages: 'Trang lỗi',
    page401: '401',
    page404: '404',
    errorLog: 'Lỗi',
    excel: 'Excel',
    exportExcel: 'Xuất Excel',
    selectExcel: 'Xuất Excel theo lựa chọn',
    uploadExcel: 'Nhập Excel',
    zip: 'Zip',
    exportZip: 'Xuất Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'Liên kết ngoài',
    elementUi: 'Thành phần giao diện',
    administrator: 'Quản trị',
    users: 'Tài khoản',
    userProfile: 'Tài khoản người dùng',
    rolePermission: 'Quyền truy cập',
    role: 'Vai trò',
    department: 'Phòng ban',
    position: 'Vị trí',
    skill: 'Kĩ năng',
    skillSheet: 'Bộ kĩ năng',
    property: 'Tài sản',
    contract: 'Hợp đồng',
    cv: 'CV',
    employees: 'Danh sách nhân viên',
    staff: 'Nhân viên',
    myProfile: 'Thông tin cá nhân',
    myCV: 'CV cá nhân',
    home: 'Trang chủ',
    team: 'Nhóm',
    chargeJobs: 'Công việc',
    project: 'Dự án',
    mySkillSheet: 'Kỹ năng của tôi',
    contact: 'Danh bạ',
    createUser: 'Thêm tài khoản',
    editUser: 'Chỉnh sửa tài khoản',
    editContract: 'Chỉnh sửa hợp đồng',
    createContract: 'Tạo hợp đồng',
    bankUser: 'Tài khoản ngân hàng',
    notification: 'Thông báo',
  },
  navbar: {
    logOut: 'Đăng xuất',
    dashboard: 'Giao diện chính',
    github: 'Github',
    screenfull: 'Toàn màn hình',
    theme: 'Theme',
    size: 'Kích thước',
    profile: 'Trang cá nhân',
    password: 'Đổi mật khẩu',
    notification: 'Thông báo',
    earlier: 'Trước đó',
  },
  login: {
    title: 'Chào mừng đến với',
    logIn: 'Đăng nhập',
    googleLogin: 'Đăng nhập với Google',
    username: 'Username',
    email: 'Email',
    password: 'Mật khẩu',
    any: 'any',
    thirdparty: 'Hoặc đăng nhập với',
    thirdpartyTips:
      'Không thể giả lập, xi vui lòng áp dụng tùy theo tình huống nghiệp vụ của bạn! ! !',
    forgotPassword: 'Quên mật khẩu',
    switchLanguage: 'Chuyển đổi ngôn ngữ thành công',
  },
  documentation: {
    documentation: 'Tài liệu',
    github: 'Github Repository',
    laravel: 'Laravel',
  },
  permission: {
    addRole: 'Thêm vai trò',
    editPermission: 'Chinh sửa quyền',
    roles: 'Nhóm của bạn',
    switchRoles: 'Đổi nhóm',
    tips: 'Trong vài trường hợp sẽ không thích hợp để dùng v-permission, như là thành phần Tab hay el-table-column và các trường hợp render DOM không đồng bộ, khi đó chỉ có thể xử lý bằng tay với v-if.',
  },
  guide: {
    description:
      'Trang hướng dẫn sẽ có ích cho những người mới vào website lần đầu. Bạn có thể giới thiệu sơ lược các chức năng của website. Kiểm tra demo',
    button: 'Hiện hướng dẫn',
  },
  components: {
    documentation: 'Tài liệu',
    tinymceTips:
      'Rich text editor is a core part of management system, but at the same time is a place with lots of problems. In the process of selecting rich texts, I also walked a lot of detours. The common rich text editors in the market are basically used, and the finally chose Tinymce. See documentation for more detailed rich text editor comparisons and introductions.',
    dropzoneTips:
      'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    stickyTips:
      'When the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1:
      'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2:
      'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips:
      'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.',
  },
  table: {
    skillCategory: 'Danh mục kỹ năng',
    skillName: 'Tên kỹ năng',
    upComing: 'Sắp tới',
    finished: 'Hoàn thành',
    showCV: 'Xem CV',
    searchCV: 'Tìm theo tên CV, tên nhân viên hoặc công nghệ',
    searchByUsername: 'Tìm theo tên nhân viên',
    searchPropertyByNameOrCode: 'Tìm theo tên hoặc mã tài sản',
    searchByName: 'Tìm theo tên',
    searchBySkill: 'Tìm theo tên kỹ năng',
    filterByCategory: 'Lọc theo danh mục kĩ năng',
    description: 'Mô tả',
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    name: 'Tên',
    title: 'Tiêu đề',
    importance: 'IMP',
    type: 'Thể loại',
    remark: 'Ghi chú',
    search: 'Tìm kiếm',
    add: 'Thêm',
    export: 'Xuất',
    reviewer: 'Người đánh giá',
    id: 'ID',
    date: 'Ngày',
    author: 'Tác giả',
    readings: 'Số lần đọc',
    status: 'Trạng thái',
    actions: 'Hành động',
    edit: 'Chỉnh sửa',
    read: 'Đọc',
    publish: 'Xuất bản',
    draft: 'Ban nháp',
    delete: 'Xóa',
    cancel: 'Hủy',
    confirm: 'Xác nhận',
    keyword: 'Từ khóa',
    role: 'Vai trò',
    createdAt: 'Ngày tạo',
    updatedAt: 'Ngày cập nhật',
    month: 'Tháng',
    content: 'Nội dung',
    confirmDelete: 'Bạn có chắc chắn muốn xóa',
    warning: 'Cảnh báo',
  },
  form: {
    submit: 'Gửi',
    cancel: 'Hủy',
  },
  errorLog: {
    tips: 'Hãy nhấn nút lỗi ở góc trên bên phải',
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Trang tài liệu',
    invalidAccount: 'Sai email hoặc mật khẩu',
    loginFail: 'Đăng nhập thất bại, vui lòng thử lại sau',
    invalidEmail: 'Email không hợp lệ',
    invalidPhoneNumber: 'Số điện thoại không hợp lệ',
    invalidUrl: 'Url không hợp lệ',
    passwordLimitLength: 'Mật khẩu phải có ít nhất 8 ký tự',
    number: 'Vui lòng nhập số',
    passwordMismatch: 'Mật khẩu không khớp',
    oldPasswordRequired: 'Mật khẩu cũ không được để trống',
    nameRequired: 'Tên không được để trống',
    userRequired: 'Nhân viên không được để trống',
    bankRequired: 'Ngân hàng không được để trống',
    bankAccountRequired: 'Số tài khoản không được để trống',
    positionRequired: 'Chức vị không được để trống',
    emailRequired: 'Email không được để trống',
    genderRequired: 'Giới tính không được để trống',
    birthdayRequired: 'Ngày sinh không được để trống',
    permanentAddressRequired: 'Địa chỉ thường trú không được để trống',
    workingStatusRequired: 'Trạng thái làm việc không được để trống',
    permanentAddressDetailRequired: 'Địa chỉ chi tiết không được để trống',
    departmentRequired: 'Phòng ban không được để trống',
    propertyRequired: 'Tài sản không được để trống',
    required: 'Trường này không được để trống',
    userNameRequired: 'Tên nhân viên không được để trống',
    cvNameRequired: 'Tên CV không được để trống',
    minOneValue: 'Vui lòng chọn ít nhất một giá trị',
    teamRequired: 'Tên nhóm không được để trống',
    projectRequired: 'Tên dự án không được để trống',
    timelineRequired: 'Timeline không được để trống',
    titleRequired: 'Tiêu đề không được để trống',
    contentRequired: 'Nội dung không được để trống',
    usedLeaveLimit:
      'Số ngày nghỉ đã sử dụng không được lớn hơn tổng số ngày nghỉ và không được nhỏ hơn 0',
  },
  excel: {
    export: 'Xuất',
    selectedExport: 'Xuất theo lựa chọn',
    placeholder: 'Vui lòng nhập tên file',
  },
  zip: {
    export: 'Xuất',
    placeholder: 'Vui lòng nhập tên file',
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading pdf.',
  },
  theme: {
    change: 'Đổi Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.',
  },
  tagsView: {
    refresh: 'Làm mới',
    close: 'Đóng',
    closeOthers: 'Đóng các tab khác',
    closeAll: 'Đóng tất cả',
  },
  user: {
    role: 'Vai trò',
    newPassword: 'Mật khẩu mới',
    oldPassword: 'Mật khẩu cũ',
    password: 'Mật khẩu',
    confirmPassword: 'Nhập lại mật khẩu',
    name: 'Tên nhân viên',
    staffCode: 'Mã nhân viên',
    email: 'Địa chỉ email',
    skill: 'Kỹ năng',
    position: 'Chức vị',
    department: 'Phòng ban',
    status: 'Trạng thái làm việc',
    uid: 'Mã nhân viên',
    working: 'Đang làm việc',
    leaveOffWork: 'Đã nghỉ việc',
    avatar: 'Ảnh đại diện',
    project: 'Dự án',
    contractCode: 'Số hợp đồng',
    // user meta
    sub_mail: 'Mail dự phòng',
    gender: 'Giới tính',
    gender_female: 'Nữ',
    gender_male: 'Nam',
    gender_another: 'Khác',
    birthday: 'Ngày sinh',
    phone_number: 'Số điện thoại',
    url_facebook: 'Url facebook',
    tax_code: 'Mã số thuế',
    exp_year: 'Năm kinh nghiệm',
    introduce_yourself: 'Giới thiệu',
    permanent_address: 'Địa chỉ thường trú',
    permanent_address_detail: 'Chi tiết địa chỉ thường trú',
    current_address: 'Địa chỉ hiện tại',
    current_address_detail: 'Chi tiết địa chỉ hiện tại',
    citizen_id: 'CCCD',
    date_of_citizen_id: 'Ngày cấp',
    place_of_citizen_id: 'Nơi cấp',
    marital_status: 'Tình trạng hôn nhân',
    marital_married: 'Đã kết hôn',
    marital_widowed: 'Góa',
    marital_separated: 'Ly thân',
    marital_divorced: 'Ly hôn',
    marital_single: 'Độc thân',
    insurance_book_number: 'Số sổ bảo hiểm',
    nationality: 'Quốc tịch',
    display_lang: 'Ngôn ngữ hiển thị',
    familiar_contact: 'Liên lạc quen thuộc',
    total_leave: 'Tổng số ngày nghỉ',
    used_leave: 'Số ngày nghỉ đã sử dụng',
    remaining_leave: 'Số ngày nghỉ còn lại',
    certificates: 'Chứng chỉ',
    properties: 'Tài sản',
    education: 'Trình độ giáo dục',
    db_os: 'Ngôn ngữ, DB, OS',
    // scan type
    scan_curriculum_vitae: 'Sơ yếu lý lịch',
    scan_citizen_id: 'CCCD',
    scan_registration_book: 'Sổ hộ khẩu',
    scan_certificate: 'Bằng cấp',
    scan_health_records: 'Hồ sơ sức khỏe',

    address: 'Tỉnh - Huyện - Xã',
    address_detail: 'Địa chỉ cụ thể',
    date_join_company: 'Ngày vào công ty',
    official_start_date: 'Ngày lên nhân viên chính thức',
    date_change_level: 'Ngày chuyển level',
    date_left_company: 'Ngày nghỉ việc',
    list_contracts: 'Danh sách hợp đồng',
    menu: {
      title: {
        information: 'Thông tin cơ bản',
        account: 'Thông tin tài khoản',
        contact: 'Thông tin liên hệ',
        job: 'Thông tin công việc',
        record: 'Hồ sơ nhân sự',
        reward: 'Đánh giá khen thưởng',
        contract: 'Thông tin hợp đồng',
        contract_termination: 'Thông tin thanh toán hợp đồng',
      },
    },
  },
  roles: {
    description: {
      admin:
        'Super Administrator. Have access and full permission to all pages.',
      manager:
        'Manager. Have access and permission to most of pages except permission page.',
      editor:
        'Editor. Have access to most of pages, full permission with articles and related resources.',
      user: 'Normal user. Have access to some pages',
      visitor:
        'Visitor. Have access to static pages, should not have any writable permission',
    },
  },
  month: {
    january: 'Tháng 1',
    february: 'Tháng 2',
    march: 'Tháng 3',
    april: 'Tháng 4',
    may: 'Tháng 5',
    june: 'Tháng 6',
    july: 'Tháng 7',
    august: 'Tháng 8',
    september: 'Tháng 9',
    october: 'Tháng 10',
    november: 'Tháng 11',
    december: 'Tháng 12',
  },
  property: {
    code: 'Mã tài sản',
    other: 'Khác',
    computer_screen: 'Màn hình máy tính',
    mouse: 'Chuột',
    keyboard: 'Bàn phím',
    computer_case: 'Case máy tính',
    laptop: 'Laptop',
    group: 'Nhóm tài sản',
    status: 'Trạng thái',
    status_description: 'Mô tả trạng thái',
    unit: 'Đơn vị',
  },
  contract: {
    scan_contract: 'Scan HĐ',
    scan_addendums: 'Phụ lục HĐ',
    scan_resignation: 'Scan Đơn xin nghỉ',
    scan_acceptance_certificate: 'Scan BBBG',
    contract_info: 'Thông tin hợp đồng',
    update_contract: 'Sửa hợp đồng',
    create_contract: 'Tạo hợp đồng',
    contract_termination_info: 'Thông tin thanh lý hợp đồng',
    upload: 'Tải lên PDF',
    fixed_term_contract: 'Có thời hạn',
    indefinite_contract: 'Vô thời hạn',
    probationary_contract: 'Thử việc',
    input: {
      label: {
        code: 'Số HĐ',
        username: 'Nhân viên',
        type: 'Loại HĐ',
        duration: 'Thời hạn HĐ',
        salary: 'Lương cơ bản',
        salary_gross: 'Lương Gross',
        sign_expiration_date: 'Ngày BĐ - KT',
        onboarded_at: 'Ngày vào làm',
        resignation_date: 'Ngày nghỉ việc',
        resignation_determination_letter_number: 'Số quyết định nghỉ việc',
        add: 'Thêm',
      },
      placeholder: {
        select_user: 'Chọn nhân viên',
        select_type: 'Chọn loại HĐ',
        start_date: 'Ngày bắt đầu',
        end_date: 'Ngày kết thúc',
        pick_a_date: 'Chọn ngày',
      },
      rule: {
        name: 'Tên nhân viên không được để trống',
        code: 'Số HĐ không được để trống',
        duration: 'Thời hạn HĐ không được để trống',
        salary: 'Lương cơ bản không được để trống',
        salary_gross: 'Lương Gross không được để trống',
        type: 'Loại HĐ không được để trống',
        type_number: 'Loại HĐ phải là kiểu sô',
        scan_contract: 'Scan HĐ không được để trống',
        sign_date: 'Ngày bắt đầu - kết thúc không được để trống',
      },
    },
    mess: {
      update_success: 'Cập nhật thành công',
      create_success: 'Tạo thành công',
      delete_success: 'Xoá thành công',
      file_size_exceeds: 'Kích thước file quá lớn (>30MB)',
    },
    table: {
      uid: 'Mã nhân viên',
      username: 'Tên nhân viên',
      sign_date: 'Ngày bắt đầu',
      expiration_date: 'Ngày kết thúc',
    },
    filter: {
      all: 'Tất cả hợp đồng',
      new_in_month: 'Hợp đồng mới trong tháng',
      expired: 'Hợp đồng đã thanh lý',
      in_effect: 'Hợp đồng còn hiệu lực',
      about_to_expire: 'Hợp đồng sắp hết hạn (tháng sau)',
    },
  },
  dashboard: {
    employees_expired_contract: 'Nhân viên hết hạn hợp đồng',
    employees_birthday_in_month: 'Nhân viên sinh nhật trong',
    birthday_in_month: 'Sinh nhật trong tháng',
    anniversary: 'Kỷ niệm',
    anniversary_texts: 'Kỷ niệm {year} năm',
    anniversary_text: 'Kỷ niệm {year} năm',
  },
  my_profile: {
    basic_information: 'Thông tin cơ bản',
    contact_information: 'Thông tin liên hệ',
    job_information: 'Thông tin việc làm',
    personal_file: 'Tài liệu cá nhân',
    performance_evaluation_rewards: 'Đánh giá hiệu suất và khen thưởng',
  },
  project: {
    name: 'Tên dự án',
    url: 'URL dự án',
    description: 'Mô tả',
    os: 'Hệ điều hành',
    language: 'Ngôn ngữ lập trình',
    tool: 'Công cụ',
    scale: 'Quy mô dự án',
    memberNumber: 'Số thành viên',
    chargeJobs: 'Công viêc phụ trách',
    members: 'Thành viên',
    timeline: 'Thời gian',
    startTime: 'Thời gian bắt đầu',
    endTime: 'Thời gian kết thúc',
    projectInfo: 'Thông tin dự án',
    achievement: 'Thành tích',
  },
  cv: {
    name: 'Tên CV',
    graduate: 'Tốt nghiệp đại học',
    staff_name: 'Tên nhân viên',
    experience: 'Số năm kinh nghiệm',
    little_yourself: 'Đôi nét về bản thân',
    language: 'Ngôn ngữ, DB, OS',
    qualifications: 'Bằng cấp, chứng chỉ',
    cvInfo: 'Thông tin CV',
    missingCV: 'Nhân viên chưa có CV, xin hãy liên hệ HR để cập nhật thông tin',
  },
  team: {
    name: 'Tên',
    description: 'Mô tả',
    leader: 'Quản lý',
    member: 'Thành viên',
  },
  message: {
    update_status_notification: 'Cập nhật trạng thái thành công',
    updateSuccess: 'Cập nhật thành công',
    sendMailSuccess: 'Gửi mail thành công',
    renewPasswordSuccess: 'Đổi mật khẩu thành công',
    deleteSuccess: 'Xóa thành công',
    addSuccess: 'Thêm thành công',
    actionCancel: 'Hủy thao tác',
    changePasswordSuccess: 'Đổi mật khẩu thành công',
    incomingFeature: 'Tính năng này đang phát triển version 2',
  },
  staff: {
    contact: {
      title: 'Danh bạ công ty',
      missing_phone_number: 'Chưa cập nhật số điện thoại',
    },
    create_event: 'Tạo sự kiện',
    edit_event: 'Chỉnh sửa',
    featured_event: 'Sự kiện nổi bật',
    happy_anniversary: 'Chúc mừng kỉ niệm',
    date: {
      today: 'Hôm nay',
      format: 'd MMMM yyyy',
    },
    happy_birthday: 'HAPPY BIRTHDAY',
    in_month_birthday: 'Sinh nhật trong tháng',
    user_name: 'Tên người dùng',
    dob: 'Ngày sinh nhật',
    form: {
      max_events: 'Hiển thị tối đa 3 sự kiện',
      title: 'Tiêu đề',
      title_placeholder: 'Nhập tiêu đề',
      link: 'Link đến bài viết',
      link_placeholder: 'Nhập link',
      is_featured: 'Đặt làm sự kiện nổi bật',
      banner: 'Banner sự kiện',
      upload: 'Kéo và thả tệp vào đây hoặc nhấp để tải lên',
      rule: {
        title_required: 'Mời nhập một tiêu đề',
        link_required: 'Mời nhập một link',
        valid_link: 'Vui lòng nhập một URL hợp lệ',
        upload_size: 'Ảnh không được nặng quá 10MB',
        files_required: 'Mời bạn upload 1 ảnh',
      },
      success_message: 'Tạo sự kiện thành công',
    },
  },
  home: {
    welcome_member: 'Chào mừng thành viên mới',
    birthday: 'Chúc mừng sinh nhật tháng',
    annual_leave_days: 'Số ngày phép cả năm',
    days_taken_off: 'Số ngày phép đã nghỉ',
    days_remaining: 'Số ngày phép còn lại',
    day: 'Ngày',
  },
  bank: {
    name: 'Tên ngân hàng',
    account: 'STK ngân hàng',
    selectUser: 'Chọn nhân viên',
    selectBank: 'Chọn ngân hàng',
  },
  language: {
    en: 'Tiếng Anh',
    vi: 'Tiếng Việt',
  },
  employee_type: {
    validation_error: 'Loại nhân viên là bắt buộc',
    title: 'Loại nhân viên',
    freelance: 'Freelance',
    contract: 'Hợp đồng',
  },
  project_provided_type: {
    validation_error: 'Loại dự án là bắt buộc',
    title: 'Loại dự án',
    internal: 'Nội bộ',
    external: 'Ngoài',
  },
};
