<template>
  <section class="app-main">
    <main
      class="py-[18px] sm:px-[30px] px-[16px] lg:ml-0 bg-[#f8f8f8] rounded-[16px] min-h-[calc(100vh-128px)]"
    >
      <transition name="fade-transform" mode="out-in">
        <!-- <keep-alive :include="cachedViews"> -->
        <router-view :key="key" />
        <!-- </keep-alive> -->
      </transition>
    </main>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
