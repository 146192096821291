import NotificationApi from '@/api/NotificationApi';
import BaseStore from './BaseStore';

const BaseNotificationStore = new BaseStore(NotificationApi);

const state = {
  notification_response: [],
  notificationOfUser: [],
  updateStatusNotificationOfUser: [],
};

const getters = {
  notificationResponse: (state) => state.notification_response,
};

const mutations = {
  SET_NOTIFICATION_RESPONSE(state, payload) {
    state.notification_response = payload;
  },
  SET_NOTIFICATION_OF_USER: (state, notificationOfUser) => {
    state.notificationOfUser = notificationOfUser;
  },
  SET_UPDATE_STATUS_NOTIFICATION: (state, updateStatusNotificationOfUser) => {
    state.updateStatusNotification = updateStatusNotificationOfUser;
  },
};

const actions = {
  ...BaseNotificationStore.actions,
  async fetchNotification({ commit }, listQuery) {
    const response = await NotificationApi.get(listQuery);
    commit('SET_NOTIFICATION_RESPONSE', response);
  },
  async getNotificationOfUser({ commit }, listQuery) {
    const response = await NotificationApi.getNotificationOfUser(listQuery);
    if (response) {
      commit('SET_NOTIFICATION_OF_USER', response);
    }
  },
  async updateStatusNotification({ commit }, id) {
    const response = await NotificationApi.updateStatusNotification(id);
    if (response) {
      commit('SET_UPDATE_STATUS_NOTIFICATION', response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
