import { removeToken } from '@/utils/auth';
import router, { resetRouter } from '@/router';
import store from '@/store';
import UserApi from '@/api/UserApi';
import BaseStore from './BaseStore';
import { getLangByValue } from '@/utils/i18n';

const BaseUserStore = new BaseStore(UserApi);

const state = {
  id: null,
  user: null,
  token: false,
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  permissions: [],
  userMeta: null,
  usersExpirationContract: [],
  usersBirthdayInMonth: [],
  usersHasAnniCurrentMonth: [],
  newUsers: [],
  usersExceptAdmin: [],
  department: null,
  position: null,
  evaluates: [],
  companyDirectory: [],
};

const mutations = {
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_USER_META: (state, userMeta) => {
    state.userMeta = userMeta;
  },
  SET_USERS_EXPIRATION_CONTRACT: (state, usersExpirationContract) => {
    state.usersExpirationContract = usersExpirationContract;
  },
  SET_USER_BIRTHDAY_IN_MONTH: (state, usersBirthdayInMonth) => {
    state.usersBirthdayInMonth = usersBirthdayInMonth;
  },
  SET_USERS_HAS_ANNI_CURRENT_MONTH: (state, usersHasAnniCurrentMonth) => {
    state.usersHasAnniCurrentMonth = usersHasAnniCurrentMonth;
  },
  SET_USERS_EXCEPT_ADMIN: (state, usersExceptAdmin) => {
    state.usersExceptAdmin = usersExceptAdmin;
  },
  SET_NEW_USERS: (state, newUsers) => {
    state.newUsers = newUsers;
  },
  SET_POSITION: (state, position) => {
    state.position = position;
  },
  SET_DEPARTMENT: (state, department) => {
    state.department = department;
  },
  SET_EVALUATES: (state, evaluates) => {
    state.evaluates = evaluates;
  },
  SET_COMPANY_DIRECTORY: (state, companyDirectory) => {
    state.companyDirectory = companyDirectory;
  },
};

const actions = {
  ...BaseUserStore.actions,

  async getUsersExceptAdmin({ commit }, query) {
    const response = await UserApi.getUsersExceptAdmin(query);
    if (response) {
      commit('SET_USERS_EXCEPT_ADMIN', response);
    }
  },
  async getNewUsers({ commit }) {
    const response = await UserApi.getNewUsers();
    if (response) {
      commit('SET_NEW_USERS', response.data);
    }
  },
  async getUsersExpirationContract({ commit }, query) {
    const response = await UserApi.getUsersExpirationContract(query);
    if (response) {
      commit('SET_USERS_EXPIRATION_CONTRACT', response.data);
    }
    return response;
  },
  async getUsersBirthdayInMonth({ commit }, query) {
    const response = await UserApi.getUsersBirthdayInMonth(query);
    if (response) {
      const formattedData = response.data.map((item) => {
        const parts = item.user_meta.birthday.split('/');
        const formattedDate = {
          day: parseInt(parts[0]),
          month: parseInt(parts[1]),
        };
        return { ...item, formattedDate };
      });

      commit('SET_USER_BIRTHDAY_IN_MONTH', formattedData);
    }
    return response;
  },
  async getUsersHasAnniCurrentMonth({ commit }, query) {
    const response = await UserApi.getUsersHasAnniCurrentMonth(query);
    if (response) {
      const formattedData = response.data.map((item) => {
        const parts = item.date_join_company.split('/');
        const formattedDate = {
          day: parseInt(parts[0]),
          month: parseInt(parts[1]),
        };
        return { ...item, formattedDate };
      });

      commit('SET_USERS_HAS_ANNI_CURRENT_MONTH', formattedData);
    }
    return response;
  },

  async upload(_, { id, data }) {
    const response = await UserApi.upload(id, data);
    return response;
  },
  async updateUser({ dispatch }, { id, data }) {
    const response = await UserApi.updateUser(id, data);
    await dispatch('getFullInfo');
    return response;
  },
  async updateSelf({ dispatch }, data) {
    const response = await UserApi.updateSelfInfo(data);
    await dispatch('getFullInfo');
    return response;
  },

  async createUser(_, { data }) {
    const response = await UserApi.createUser(data);
    return response;
  },

  // get user info
  async getInfo({ commit }) {
    const response = await UserApi.getInfo();
    if (response.data) {
      const { data } = response;
      if (data.roles !== 'undefined' && data.roles.length) {
        const { roles, name, avatar_url, permissions, id } = data;
        commit('SET_ROLES', roles);
        commit('SET_PERMISSIONS', permissions);
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar_url || '');
        commit('SET_ID', id);
        commit('SET_USER', data);
        return data;
      }
    }

    await removeToken();
    await resetRouter();
    return false;
  },

  async getFullInfo({ commit, dispatch }) {
    const response = await UserApi.getFullInfo();
    if (response) {
      const { data } = response;
      const {
        roles,
        name,
        avatar_url,
        id,
        user_meta,
        permissions,
        department,
        position,
        evaluates,
      } = data;
      commit('SET_ROLES', roles);
      commit('SET_PERMISSIONS', permissions);
      commit('SET_NAME', name);
      commit('SET_DEPARTMENT', department);
      commit('SET_POSITION', position);
      commit('SET_AVATAR', avatar_url || '');
      commit('SET_INTRODUCTION', user_meta?.introduce_yourself || '');
      commit('SET_ID', id);
      commit('SET_USER', data);
      commit('SET_USER_META', user_meta);
      commit('SET_EVALUATES', evaluates);
      if (user_meta?.display_lang) {
        dispatch(
          'app/setLanguage',
          getLangByValue(user_meta.display_lang).name,
          {
            root: true,
          }
        );
      }
      return data;
    }

    await removeToken();
    await resetRouter();
    return false;
  },

  async updateSelfInfo({ dispatch }, data) {
    const response = await UserApi.updateSelfInfo(data);
    await dispatch('getFullInfo');
    return response;
  },

  // Dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise((resolve) => {
      // const token = role + '-token';

      // commit('SET_TOKEN', token);
      // setLogged(token);

      // const { roles } = await dispatch('getInfo');

      const roles = [role.name];
      const permissions = role.permissions.map((permission) => permission.name);
      commit('SET_ROLES', roles);
      commit('SET_PERMISSIONS', permissions);
      resetRouter();

      // generate accessible routes map based on roles
      const accessRoutes = store.dispatch('permission/generateRoutes', {
        roles,
        permissions,
      });

      // dynamically add accessible routes
      router.addRoutes(accessRoutes);

      resolve();
    });
  },

  async fetchAvailableUsers(state, param) {
    const response = await UserApi.getUsersAvailable(param);
    if (response) {
      return response;
    }
    return false;
  },

  async changePassword(context, data) {
    return await UserApi.changePassword(data);
  },

  async getCompanyDirectory({ commit }, data) {
    const response = await UserApi.getCompanyDirectory(data);
    if (response) {
      commit('SET_COMPANY_DIRECTORY', response);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
