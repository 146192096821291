import BankApi from '@/api/BankApi';
import BaseStore from './BaseStore';

const BaseBankStore = new BaseStore(BankApi);

const state = {
  bank_response: [],
};

const getters = {
  bankResponse: (state) => state.bank_response,
};

const mutations = {
  SET_BANK_RESPONSE(state, payload) {
    state.bank_response = payload;
  },
};

const actions = {
  ...BaseBankStore.actions,
  async fetchBanks(context, listQuery = []) {
    const response = await BankApi.get(listQuery);

    if (response) {
      context.commit('SET_BANK_RESPONSE', response);
      return response;
    }
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
