// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title) {
  const hasKey = this.$te('route.' + title);

  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    const translatedTitle = this.$t('route.' + title);

    return translatedTitle;
  }
  return title;
}

import { LANGUAGE_TYPE } from '@/constants/type';

export function getLangByValue(displayLang) {
  return LANGUAGE_TYPE.filter((item) => {
    return item.value === displayLang;
  })[0];
}
