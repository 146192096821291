import RoleApi from '@/api/RoleApi';
import BaseStore from './BaseStore';

const BaseRoleStore = new BaseStore(RoleApi);

const state = {
  role_response: [],
};

const getters = {};

const mutations = {};

const actions = {
  ...BaseRoleStore.actions,
  async getAllRolesName(state) {
    const response = await RoleApi.getAllRolesName();
    if (response) {
      return response;
    }
    return [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
