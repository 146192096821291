import SkillSheetApi from '@/api/SkillSheetApi';
import BaseStore from './BaseStore';

const BaseSkillSheetStore = new BaseStore(SkillSheetApi);

const state = {
  skill_sheet_response: [],
};

const getters = {
  skillSheetResponse: (state) => state.skill_sheet_response,
};

const mutations = {
  SET_SKILL_SHEET_RESPONSE(state, payload) {
    state.skill_sheet_response = payload;
  },
};

const actions = {
  ...BaseSkillSheetStore.actions,
  async fetchSkillSheets({ commit }, listQuery) {
    const response = await SkillSheetApi.get(listQuery);
    if (response) {
      commit('SET_SKILL_SHEET_RESPONSE', response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
