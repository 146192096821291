import EventApi from '@/api/EventApi';
import BaseStore from './BaseStore';

const BaseEventStore = new BaseStore(EventApi);

const state = {
  event_response: [],
};

const getters = {
  eventResponse: (state) => state.event_response,
};

const mutations = {
  SET_EVENT_RESPONSE(state, payload) {
    state.event_response = payload;
  },
};

const actions = {
  ...BaseEventStore.actions,
  async get(context, listQuery) {
    const response = await EventApi.get(listQuery);
    if (response) {
      context.commit('SET_EVENT_RESPONSE', response);
    }
  },
  async store(context, listQuery) {
    const response = await EventApi.store(listQuery);
    if (response) {
      context.commit('SET_EVENT_RESPONSE', response);
    }
    return response;
  },

  async update(context, { id, data }) {
    const response = await EventApi.update(id, data);
    if (response) {
      context.commit('SET_EVENT_RESPONSE', response);
    }
    return response;
  },
  async getEvents(context, listQuery) {
    const response = await EventApi.getEvents(listQuery);
    if (response.data) {
      context.commit('SET_EVENT_RESPONSE', response.data);
    }
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
