import Cookies from 'js-cookie';
// import axios from 'axios';

const tokenKey = 'token';

export function isLogged() {
  return getToken();
}

export function setToken(token) {
  return Cookies.set(tokenKey, token);
}

export function removeToken() {
  return Cookies.remove(tokenKey);
}

export function getToken() {
  return Cookies.get(tokenKey);
}
