import PositionApi from '@/api/PositionApi';
import BaseStore from './BaseStore';

const BasePositionStore = new BaseStore(PositionApi);

const state = {
  position_response: [],
};

const getters = {
  positionResponse: (state) => state.position_response,
};

const mutations = {
  SET_POSITION_RESPONSE(state, payload) {
    state.position_response = payload;
  },
};

const actions = {
  ...BasePositionStore.actions,
  async fetchPositions({ commit }, listQuery) {
    const response = await PositionApi.get(listQuery);
    commit('SET_POSITION_RESPONSE', response);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
