<template>
  <div v-if="!item.hidden && item.children" class="menu-wrapper">
    <template v-for="child in visibleChildren">
      <el-submenu v-if="child.children && child.children.length > 0" :key="child.path" :index="resolvePath(item.path)">
        <template slot="title">
          <item
            v-if="child.meta"
            :icon="child.meta.icon"
            :title="generateTitle(child.meta.title)"
          />
        </template>
        <sidebar-item
          :is-nest="true"
          :item="child"
          :base-path="resolvePath(child.path)"
          class="nest-menu"
        />
      </el-submenu>
      <app-link v-else :key="child.name" :to="resolvePath(child.path)">
        <el-menu-item :index="resolvePath(child.path)">
          <item
            v-if="child.meta"
            :icon="child.meta.icon"
            :title="generateTitle(child.meta.title)"
          />
        </el-menu-item>
      </app-link>
    </template>
  </div>
</template>

<script>
import path from 'path';
import { isExternal } from '@/utils/validate';
import Item from './Item';
import AppLink from './Link';
import { generateTitle } from '@/utils/i18n';

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  computed: {
    visibleChildren() {
      return this.item.children.filter((item) => !item.hidden);
    },
  },
  methods: {
    hasOneShowingChild(children, parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath) {
      if (this.isExternalLink(routePath)) {
        return routePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    isExternalLink(routePath) {
      return isExternal(routePath);
    },
    generateTitle,
  },
};
</script>

<style lang="scss" scoped>
.menu-wrapper {
  margin: 0px 15px !important;

  .el-menu-item {
    border-radius: 10px ;
    padding-left: 28px !important;
    font-weight: 500 !important;
    .svg-icon {
      height: 1.3rem;
      width: 1.3rem;
      vertical-align: -5px !important
    }

    &:hover {
      // you can use $subMenuHover
      background-color:#ffff !important;
      color: #CD3131 !important;
    }
  }

  .el-menu-item.is-active {
    background: #FFEEE4 !important;
  }
  .el-submenu {
    font-weight: 500 !important;
    border-radius: 10px !important;
    overflow: hidden;
  }

  .menu-wrapper .nest-menu {
    .el-menu-item.is-active {
      background: #FFEEE4 !important;
    }
  }
}
</style>
