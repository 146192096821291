import Layout from '@/layout';

const staffRoutes = {
  path: '/staff',
  component: Layout,
  redirect: '/staff/my-profile',
  name: 'Staff',
  alwaysShow: false,
  meta: {
    title: 'staff',
    icon: 'staff',
  },
  children: [
    {
      path: 'home',
      component: () => import('@/views/home/index'),
      name: 'Home',
      meta: { title: 'home', icon: 'home', noCache: true },
    },
    {
      path: 'my-profile',
      component: () => import('@/views/my-profile/index'),
      name: 'MyProfile',
      meta: { title: 'myProfile', icon: 'profile', noCache: true },
    },
    {
      path: 'my-cv',
      component: () => import('@/views/my-skill-sheet/index'),
      name: 'myCV',
      meta: { title: 'myCV', icon: 'my-cv', noCache: true },
    },
    {
      path: 'contacts',
      component: () => import('@/views/contacts/index'),
      name: 'Contact',
      meta: { title: 'contact', icon: 'contact', noCache: true },
    },
  ],
};
export default staffRoutes;
