import BaseApi from './BaseApi';

const baseUrl = 'properties';

class PropertyApi extends BaseApi {
  getPropertyGroup() {
    return this.axios.get(this.baseUrl + '/groups').catch((error) => {
      throw new Error(error);
    });
  }

  getPropertyStatus() {
    return this.axios.get(this.baseUrl + '/statuses').catch((error) => {
      throw new Error(error);
    });
  }
}

export default new PropertyApi(baseUrl);
