import BaseApi from './BaseApi';
import axios from 'axios';
import { getToken } from '@/utils/auth';
const baseUrl = 'posts';

class EventApi extends BaseApi {
  getEvents(param = []) {
    return axios
      .get(this.baseUrl + '/get-events', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .catch((error) => {
        return error.response;
      });
  }
  update(id, data) {
    console.log(data);
    data.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });

    return this.axios.post(this.baseUrl + `/${id}`, data);
  }
}

export default new EventApi(baseUrl);
