import BaseApi from './BaseApi';
import axios from 'axios';
import { getToken } from '@/utils/auth';

const baseUrl = 'roles';

class RoleApi extends BaseApi {
  permissions(id) {
    return axios
      .post(this.baseUrl + `/${id}` + '/permissions', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .catch((error) => {
        return error.response;
      });
  }

  getAllRolesName() {
    return this.axios.get(this.baseUrl + '/get-all-roles-name').catch((error) => {
      throw new Error(error);
    });
  }
}

export default new RoleApi(baseUrl);
