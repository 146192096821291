<template>
  <el-dialog
    :title="$t('navbar.password')"
    :visible.sync="visible"
    width="350px"
    :before-close="close"
    destroy-on-close
  >
    <el-form ref="form" :model="data" :rules="rules">
      <el-form-item :label="$t('user.oldPassword')" prop="old_password">
        <el-input
          v-model="data.old_password"
          show-password
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item :label="$t('user.password')" prop="new_password">
        <el-input
          v-model="data.new_password"
          show-password
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item :label="$t('user.confirmPassword')" prop="confirm_password">
        <el-input
          v-model="data.confirm_password"
          show-password
          autocomplete="off"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('table.cancel') }}</el-button>
      <el-button v-loading="isLoading" type="primary" @click="submit">{{
        $t('table.confirm')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { validateConfirmPassword, validatePassword } from '@/utils/form';
import { mapActions } from 'vuex';
const defaultForm = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};
export default {
  data() {
    return {
      isLoading: false,
      visible: false,
      data: { ...defaultForm },
      rules: {
        old_password: [
          {
            required: true,
            message: this.$t('errorLog.oldPasswordRequired'),
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (this.errorMessage.old_password) {
                callback(this.errorMessage.old_password);
              }
              callback();
            },
            trigger: 'blur',
          },
        ],
        new_password: [
          { required: true, validator: validatePassword, trigger: 'blur' },
          {
            validator: (rule, vale, callback) => {
              if (this.errorMessage.new_password) {
                callback(this.errorMessage.new_password);
              }
              callback();
            },
          },
        ],
        confirm_password: [
          {
            required: true,
            validator: (rule, value, callback) =>
              validateConfirmPassword(
                rule,
                value,
                callback,
                this.data.new_password
              ),
            trigger: 'blur',
          },
        ],
      },
      errorMessage: {
        old_password: '',
        new_password: '',
      },
    };
  },
  watch: {
    data: {
      handler() {
        if (this.errorMessage.new_password) {
          this.errorMessage.new_password = '';
        }
        if (this.errorMessage.old_password) {
          this.errorMessage.old_password = '';
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('UserStore', ['changePassword']),
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.data = { ...defaultForm };
      this.$refs.form.clearValidate();
    },

    async submit() {
      this.isLoading = true;
      try {
        let valid = false;
        this.$refs.form.validate((v) => {
          valid = v;
        });
        if (!valid) {
          return;
        }
        await this.changePassword(this.data);
        this.close();
        this.$message.success(this.$t('message.changePasswordSuccess'));
      } catch (error) {
        this.handleCheckPasswordError(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleCheckPasswordError(error) {
      if (error?.response?.data) {
        this.errorMessage = error?.response?.data;
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-form-item__label) {
  text-align: left;
}
</style>
