import BaseApi from './BaseApi';

const baseUrl = 'projects';

class ProjectApi extends BaseApi {
  getSelfProjects() {
    return this.axios
      .get(this.baseUrl + '/get-self-projects')
      .catch((error) => {
        throw new Error(error);
      });
  }
}

export default new ProjectApi(baseUrl);
