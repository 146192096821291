
import UserMetaApi from '@/api/UserMetaApi';
import BaseStore from './BaseStore';

const BaseUserStore = new BaseStore(UserMetaApi);

const state = {

};

const mutations = {

};

const actions = {
  ...BaseUserStore.actions,

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
