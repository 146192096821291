import DepartmentApi from '@/api/DepartmentApi';
import BaseStore from './BaseStore';

const BaseDepartmentStore = new BaseStore(DepartmentApi);

const state = {
  department_response: [],
};

const getters = {
  departmentResponse: (state) => state.department_response,
};

const mutations = {
  SET_DEPARTMENT_RESPONSE(state, payload) {
    state.department_response = payload;
  },
};

const actions = {
  ...BaseDepartmentStore.actions,
  async fetchDepartments(context, listQuery) {
    const response = await DepartmentApi.get(listQuery);
    if (response) {
      context.commit('SET_DEPARTMENT_RESPONSE', response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
