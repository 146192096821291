export function convertToValidObject(obj) {
  const invalidValues = ['', null, undefined];
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (!invalidValues.includes(obj[key])) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
}
