import ChargeJobApi from '@/api/ChargeJobApi';
import BaseStore from './BaseStore';

const BaseChargeJobStore = new BaseStore(ChargeJobApi);

const state = {
  charge_jobs_response: [],
};

const getters = {
  chargeJobsResponse: (state) => state.charge_jobs_response,
};

const mutations = {
  SET_CHARGE_JOB_RESPONSE(state, payload) {
    state.charge_jobs_response = payload;
  },
};

const actions = {
  ...BaseChargeJobStore.actions,
  async fetchChargeJobs({ commit }, listQuery) {
    const response = await ChargeJobApi.get(listQuery);
    if (response) {
      commit('SET_CHARGE_JOB_RESPONSE', response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
