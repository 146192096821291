export default {
  route: {
    dashboard: 'Dashboard',
    introduction: 'Introduction',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directives',
    icons: 'Icons',
    components: 'Components',
    componentIndex: 'Introduction',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    dndList: 'Dnd List',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'CountTo',
    componentMixin: 'Mixin',
    backToTop: 'BackToTop',
    dragDialog: 'Drag Dialog',
    dragSelect: 'Drag Select',
    dragKanban: 'Drag Kanban',
    charts: 'Charts',
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
    example: 'Example',
    nested: 'Nested Routes',
    menu1: 'Menu 1',
    'menu1-1': 'Menu 1-1',
    'menu1-2': 'Menu 1-2',
    'menu1-2-1': 'Menu 1-2-1',
    'menu1-2-2': 'Menu 1-2-2',
    'menu1-3': 'Menu 1-3',
    menu2: 'Menu 2',
    table: 'Table',
    dynamicTable: 'Dynamic Table',
    dragTable: 'Drag Table',
    inlineEditTable: 'Inline Edit',
    complexTable: 'Complex Table',
    treeTable: 'Tree Table',
    customTreeTable: 'Custom TreeTable',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Articles',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    mergeHeader: 'Merge Header',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link',
    elementUi: 'Element UI',
    administrator: 'Administrator',
    users: 'Users',
    userProfile: 'User Profile',
    employees: 'List of employees',
    department: 'Department',
    position: 'Position',
    skill: 'Skill',
    skillSheet: 'Skill Sheet',
    property: 'Property',
    contract: 'Contract',
    cv: 'CV',
    role: 'Role',
    staff: 'Staff',
    myProfile: 'My Profile',
    myCV: 'My CV',
    team: 'Team',
    home: 'Home',
    chargeJobs: 'Charge Jobs',
    project: 'Project',
    mySkillSheet: 'My Skill Sheet',
    contact: 'Contact',
    createUser: 'Create User',
    editUser: 'Edit User',
    editContract: 'Edit Contract',
    createContract: 'Create Contract',
    bankUser: 'Bank User',
    notification: 'Notification',
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    github: 'Github',
    theme: 'Theme',
    size: 'Global Size',
    profile: 'Profile',
    password: 'Change password',
    notification: 'Notification',
    earlier: 'Earlier',
  },
  login: {
    title: 'Welcome to',
    logIn: 'Login',
    googleLogin: 'Login via Google',
    username: 'Username',
    password: 'Password',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips:
      'Can not be simulated on local, so please combine you own business simulation! ! !',
    email: 'Email',
    forgotPassword: 'Forgot password',
    switchLanguage: 'Switch language success',
  },
  documentation: {
    documentation: 'Documentation',
    laravel: 'Laravel',
    github: 'Github Repository',
  },
  permission: {
    addRole: 'New Role',
    editPermission: 'Edit Permission',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases it is not suitable to use v-role/v-permission, such as element Tab component or el-table-column and other asynchronous rendering dom cases which can only be achieved by manually setting the v-if with checkRole or/and checkPermission.',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
  guide: {
    description:
      'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide',
  },
  components: {
    documentation: 'Documentation',
    tinymceTips:
      'Rich text editor is a core part of management system, but at the same time is a place with lots of problems. In the process of selecting rich texts, I also walked a lot of detours. The common rich text editors in the market are basically used, and the finally chose Tinymce. See documentation for more detailed rich text editor comparisons and introductions.',
    dropzoneTips:
      'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    stickyTips:
      'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1:
      'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2:
      'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips:
      'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.',
  },
  table: {
    skillCategory: 'Category',
    skillName: 'Skill Name',
    upComing: 'Up Coming',
    finished: 'Finished',
    showCV: 'Show CV',
    searchCV: 'Search CV by name, user, technology',
    searchByUsername: 'Search by user name',
    searchPropertyByNameOrCode: 'Search by name or code',
    searchByName: 'Search by name',
    searchBySkill: 'Search by skill name',
    filterByCategory: 'Filter by skill category',
    description: 'Description',
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    name: 'Name',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    read: 'Read',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    keyword: 'Search...',
    role: 'Role',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    month: 'Month',
    content: 'Content',
    confirmDelete: 'Are you sure to delete',
    warning: 'Warning',
  },
  form: {
    submit: 'Submit',
    cancel: 'Cancel',
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
    invalidAccount: 'Email or password is invalid',
    loginFail: 'Login failed, please try again later',
    invalidEmail: 'Email invalid format',
    invalidPhoneNumber: 'Phone number invalid format',
    invalidUrl: 'Url invalid format',
    passwordLimitLength: 'Password must be at least 8 characters',
    number: 'This field must be a number',
    passwordMismatch: 'Password is mismatched!',
    oldPasswordRequired: 'Old password is required',
    nameRequired: 'Name is required',
    userRequired: 'User is required',
    bankRequired: 'Bank is required',
    bankAccountRequired: 'Account number is required',
    positionRequired: 'Position is required',
    emailRequired: 'Email is required',
    genderRequired: 'Gender is required',
    birthdayRequired: 'Birthday is required',
    permanentAddressRequired: 'Permanent address is required',
    workingStatusRequired: 'Working status is required',
    permanentAddressDetailRequired: 'Permanent address detail is required',
    departmentRequired: 'Department is required',
    propertyRequired: 'Property name is required',
    required: 'This field is required',
    userNameRequired: 'User name is required',
    cvNameRequired: 'CV name is required',
    minOneValue: 'Please select at least one value',
    teamRequired: 'Team name is required',
    projectRequired: 'Project name is required',
    timelineRequired: 'Timeline is required',
    titleRequired: 'Title is required',
    contentRequired: 'Content is required',
    usedLeaveLimit:
      'Used leave must be less than total leave and greater than 0',
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name(default excel-list)',
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name(default file)',
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading pdf.',
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.',
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo',
  },
  user: {
    role: 'Role',
    newPassword: 'New password',
    oldPassword: 'Old password',
    password: 'Password',
    confirmPassword: 'Confirm password',
    name: 'Staff Name',
    staffCode: 'Staff ID',
    email: 'Email',
    skill: 'Skill',
    position: 'Position',
    department: 'Departments',
    status: 'Status',
    uid: 'UID',
    working: 'Working',
    leaveOffWork: 'Leave off work',
    avatar: 'Avatar',
    project: 'Project',
    contractCode: 'Contract Code',
    // user meta
    sub_mail: 'Sub mail',
    gender: 'Gender',
    gender_female: 'Female',
    gender_male: 'Male',
    gender_another: 'Another',
    birthday: 'Birthday',
    phone_number: 'Phone number',
    url_facebook: 'Url facebook',
    tax_code: 'Tax code',
    exp_year: 'Exp year',
    introduce_yourself: 'Introduce yourself',
    permanent_address: 'Permanent address',
    permanent_address_detail: 'Permanent address detail',
    current_address: 'Current address',
    current_address_detail: 'Current address detail',
    citizen_id: 'Citizen id',
    date_of_citizen_id: 'Date',
    place_of_citizen_id: 'Place',
    marital_status: 'Marital status',
    marital_married: 'Married',
    marital_widowed: 'Widowed',
    marital_separated: 'Separated',
    marital_divorced: 'Divorced',
    marital_single: 'Single',
    insurance_book_number: 'Insurance book number',
    nationality: 'Nationality',
    display_lang: 'Display language',
    familiar_contact: 'Familiar contact',
    total_leave: 'Total leave',
    used_leave: 'Used leave',
    remaining_leave: 'Remaining leave',
    certificates: 'Certificates',
    properties: 'Properties',
    education: 'Education',
    db_os: 'Language, DB, OS',
    // scan type
    scan_curriculum_vitae: 'Curriculum vitae',
    scan_citizen_id: 'Citizen id',
    scan_registration_book: 'Registration book',
    scan_certificate: 'Certificate',
    scan_health_records: 'Health records',
    address: 'Province - District - Commune',
    address_detail: 'Address detail',
    date_join_company: 'Date of joining the company',
    official_start_date: 'Date of official employee appointment',
    date_change_level: 'Date of change level',
    date_left_company: 'Date of left company',
    list_contracts: 'List contracts',
    menu: {
      title: {
        information: 'Basic information',
        account: 'Account information',
        contact: 'Contact Info',
        job: 'Job Info',
        record: 'HR records',
        reward: 'Reward rating',
      },
    },
  },
  roles: {
    description: {
      admin:
        'Super Administrator. Have access and full permission to all pages.',
      manager:
        'Manager. Have access and permission to most of pages except permission page.',
      editor:
        'Editor. Have access to most of pages, full permission with articles and related resources.',
      user: 'Normal user. Have access to some pages',
      visitor:
        'Visitor. Have access to static pages, should not have any writable permission',
    },
  },
  month: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  property: {
    code: 'Code',
    other: 'Other',
    computer_screen: 'Computer Screen',
    mouse: 'Mouse',
    keyboard: 'Keyboard',
    computer_case: 'Computer Case',
    laptop: 'Laptop',
    group: 'Group',
    status: 'Status',
    status_description: 'Status Description',
    unit: 'Unit',
  },
  contract: {
    scan_contract: 'Contract',
    scan_addendums: 'Addendums',
    scan_resignation: 'Resignation',
    scan_acceptance_certificate: 'Acceptance Certificate',
    contract_info: 'Contract Info',
    update_contract: 'Update Contract',
    create_contract: 'Create Contract',
    contract_termination_info: 'Contract Termination Info',
    upload: 'Upload PDF',
    fixed_term_contract: 'Fixed term',
    indefinite_contract: 'Indefinite term',
    probationary_contract: 'Probationary',
    input: {
      label: {
        code: 'Code',
        username: 'User',
        type: 'Type',
        duration: 'Duration',
        salary: 'Salary',
        salary_gross: 'Salary Gross',
        sign_expiration_date: 'Sign Date & Expiration Date',
        onboarded_at: 'Onboarded At',
        resignation_date: 'Resignation Date',
        resignation_determination_letter_number:
          'Resignation Determination Numb',
        add: 'Add',
      },
      placeholder: {
        select_user: 'Select user',
        select_type: 'Select Type',
        start_date: 'Start date',
        end_date: 'End date',
        pick_a_date: 'Please pick a date',
      },
      rule: {
        name: 'User name is required',
        code: 'Code is required',
        duration: 'Duration is required',
        salary: 'Salary is required',
        salary_gross: 'Salary Gross is required',
        type: 'Type is required',
        type_number: 'Type must be a number',
        scan_contract: 'Scan Contract is required',
        sign_date: 'Sign Date & Expiration Date is required',
      },
    },
    mess: {
      update_success: 'Updated successfully',
      create_success: 'Created successfully',
      delete_success: 'Delete successfully',
      file_size_exceeds: 'File size exceeds the limit (30MB)',
    },
    table: {
      uid: 'Staff ID',
      username: 'Staff Name',
      sign_date: 'Sign Date',
      expiration_date: 'Expiration Date',
    },
    filter: {
      all: 'All contracts',
      new_in_month: 'New contracts this month',
      expired: 'Expired contracts',
      in_effect: 'In-Effect contracts',
      about_to_expire: 'About-to-expire contracts (next month)',
    },
  },
  dashboard: {
    employees_expired_contract: 'Employees with expired contract',
    employees_birthday_in_month: 'Employee with a birthday in month',
    birthday_in_month: 'Birthday in Month',
    anniversary: 'Anniversary',
    anniversary_texts: '{year} years anniversary',
    anniversary_text: '{year} year anniversary',
  },
  my_profile: {
    basic_information: 'Basic information',
    contact_information: 'Contact information',
    job_information: 'Job information',
    personal_file: 'Personal file',
    performance_evaluation_rewards: 'Performance evaluation and rewards',
  },
  project: {
    name: 'Project Name',
    url: 'Project URL',
    description: 'Project Description',
    os: 'Operation System',
    language: 'Programming Language',
    tool: 'Tool',
    scale: 'Scale',
    memberNumber: 'Member Number',
    chargeJobs: 'Charge Jobs',
    members: 'Members',
    timeline: 'Timeline',
    startTime: 'Start Time',
    endTime: 'End Time',
    projectInfo: 'Project Information',
    achievement: 'Achievement',
  },
  cv: {
    name: 'CV Name',
    graduate: 'Graduate',
    staff_name: 'Staff name',
    experience: 'Number of years of experience',
    little_yourself: 'A little bit about yourself',
    language: 'Language, DB, OS',
    qualifications: 'Qualifications',
    cvInfo: 'CV Information',
    missingCV: 'CV is missing, Please contact HR to update',
  },
  team: {
    name: 'Name',
    description: 'Description',
    leader: 'Leader',
    member: 'Member',
  },
  message: {
    update_status_notification: 'Update Status Success',
    updateSuccess: 'Update Success',
    sendMailSuccess: 'Send mail success',
    renewPasswordSuccess: 'Renew password success',
    deleteSuccess: 'Delete Success',
    addSuccess: 'Add Success',
    actionCancel: 'Action Cancel',
    changePasswordSuccess: 'Change password success',
    incomingFeature: 'This feature is in development',
  },
  staff: {
    contact: {
      title: 'Company Directory',
      missing_phone_number: 'Phone number is missing',
    },
    create_event: 'Create Event',
    edit_event: 'Edit Event',
    featured_event: 'Featured Events',
    happy_anniversary: 'Happy Anniversary',
    date: {
      today: 'Today',
      format: 'MMMM d, yyyy',
    },
    happy_birthday: 'HAPPY BIRTHDAY',
    in_month_birthday: 'Birthdays this month',
    user_name: 'Username',
    dob: 'Date of Birth',
    form: {
      max_events: 'Displays a maximum of 3 events',
      title: 'Title',
      title_placeholder: 'Enter title',
      link: 'Link to the post',
      link_placeholder: 'Enter link',
      is_featured: 'Set as featured event',
      banner: 'Event banner',
      upload: 'Drop file here or click to upload',
      rule: {
        title_required: 'Please enter a title',
        link_required: 'Please enter a link',
        valid_link: 'Please enter a valid URL',
        upload_size: 'Image should not exceed 10MB',
        files_required: 'Please upload a file',
      },
      success_message: 'Create Event Successfully',
    },
  },
  home: {
    welcome_member: 'Welcome new member',
    birthday: 'Happy month birthday',
    annual_leave_days: 'Number of annual leave days',
    days_taken_off: 'Number of days taken off',
    days_remaining: 'Number of days remaining',
    day: 'Days',
  },
  bank: {
    name: 'Bank Name',
    account: 'Bank Account',
    selectUser: 'Select User',
    selectBank: 'Select Bank',
  },
  language: {
    en: 'English',
    vi: 'Vietnamese',
  },
  employee_type: {
    validation_error: 'Employee type is required',
    title: 'Employee Type',
    freelance: 'Freelance',
    contract: 'Contract',
  },
  project_provided_type: {
    validation_error: 'Project provided type is required',
    title: 'Project Provided Type',
    internal: 'Internal',
    external: 'External',
  },
};
