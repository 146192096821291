import BaseApi from './BaseApi';
import axios from 'axios';
import { getToken } from '@/utils/auth';

const baseUrl = 'notifications';

class NotificationApi extends BaseApi {
  getNotificationOfUser(param = []) {
    return axios
      .get(this.baseUrl + '/notifications-of-user', {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
        params: param,
      })
      .catch((error) => {
        return error.response;
      });
  }

  updateStatusNotification(id) {
    return axios
      .post(this.baseUrl + '/update-status' + `/${id}`, null, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .catch((error) => {
        return error.response;
      });
  }
}

export default new NotificationApi(baseUrl);
