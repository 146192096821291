import BankUserApi from '@/api/BankUserApi';
import BaseStore from './BaseStore';

const BaseBankUserStore = new BaseStore(BankUserApi);

const state = {
  bank_user_response: [],
};

const getters = {
  bankUserResponse: (state) => state.bank_user_response,
};

const mutations = {
  SET_BANK_USER_RESPONSE(state, payload) {
    state.bank_user_response = payload;
  },
};

const actions = {
  ...BaseBankUserStore.actions,
  async fetchBankUsers({ commit }, listQuery) {
    const response = await BankUserApi.get(listQuery);
    if (response) {
      commit('SET_BANK_USER_RESPONSE', response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
