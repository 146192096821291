import BaseApi from './BaseApi';

const baseUrl = 'users';

class UserApi extends BaseApi {
  getInfo() {
    return this.axios
      .get(process.env.BASE_API + '/users/base-info')
      .catch((error) => {
        throw new Error(error);
      });
  }

  getFullInfo() {
    return this.axios
      .get(process.env.BASE_API + '/users/full-info')
      .catch((error) => {
        throw new Error(error);
      });
  }

  updateSelfInfo(data) {
    return this.axios
      .put(this.baseUrl + '/update-self-info', data)
      .catch((error) => {
        throw new Error(error);
      });
  }

  updateUser(id, data) {
    return this.axios.patch(this.baseUrl + `/${id}`, data).catch((error) => {
      throw new Error(error);
    });
  }

  createUser(data) {
    return this.axios.post(this.baseUrl, data);
  }

  upload(id, data) {
    return this.axios
      .post(this.baseUrl + `/${id}/upload`, data)
      .catch((error) => {
        throw new Error(error);
      });
  }

  getUsersExpirationContract(param = []) {
    return this.axios
      .get(this.baseUrl + '/get-users-expiration-contract', {
        params: param,
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  getUsersBirthdayInMonth(param = []) {
    return this.axios
      .get(this.baseUrl + '/get-users-has-birthday-same-month', {
        params: param,
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  getUsersHasAnniCurrentMonth(param = []) {
    return this.axios
      .get(this.baseUrl + '/get-users-has-anni-current-month', {
        params: param,
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  getNewUsers() {
    return this.axios.get(this.baseUrl + '/get-new-users').catch((error) => {
      throw new Error(error);
    });
  }

  getUsersExceptAdmin(query = {}) {
    const { keyword, limit, page } = query;
    let url = `${this.baseUrl}?user_meta&status=1`;
    if (limit) {
      url += `&limit=${limit}&page=${page}`;
    }
    if (keyword) {
      url += `&keyword=${keyword}`;
    }
    return this.axios.get(url).catch((error) => {
      throw new Error(error);
    });
  }

  getUsersAvailable(param = {}) {
    return this.axios
      .get(this.baseUrl + '/get-users-available', {
        params: param,
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  changePassword(data) {
    return this.axios.post(`${this.baseUrl}/change-password`, data);
  }

  getCompanyDirectory(param = {}) {
    return this.axios
      .get(this.baseUrl + '/get-company-directory', {
        params: param,
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
}

export default new UserApi(baseUrl);
