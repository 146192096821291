import BaseApi from './BaseApi';

const baseUrl = 'contracts';

class ContractApi extends BaseApi {
  update(id, data) {
    return this.axios.post(this.baseUrl + `/${id}`, data);
  }

  store(createData) {
    return this.axios.post(this.baseUrl, createData);
  }
}

export default new ContractApi(baseUrl);
