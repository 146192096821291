import { validEmail, validURL } from './validate';
import i18n from '../lang';

export const checkEmail = (rule, value, callback) => {
  if (value && !validEmail(value)) {
    callback(i18n.t('errorLog.invalidEmail'));
  }
  callback();
};
export const checkEmailExits = (rule, value, callback, errorMessage) => {
  if (errorMessage) {
    callback(errorMessage);
  }
  callback();
};

export const checkPhoneNumber = (rule, value, callback) => {
  if (value && !/^([0|\+[0-9]{1,5})?([7-9][0-9]{10})$/.test(value)) {
    callback(i18n.t('errorLog.invalidPhoneNumber'));
  }
  callback();
};
export const checkUrl = (rule, value, callback) => {
  if (value && !validURL(value)) {
    callback(i18n.t('errorLog.invalidUrl'));
  }
  callback();
};

export const checkType = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('errorLog.required')));
  } else if (!Number(value) && Number(value) !== 0) {
    callback(new Error(i18n.t('errorLog.number')));
  } else {
    callback();
  }
};

export const checkNumber = (rule, value, callback) => {
  if (value && !Number(value)) {
    callback(i18n.t('errorLog.number'));
  }
  callback();
};

export const validateConfirmPassword = (rule, value, callback, password) => {
  if (value !== password) {
    callback(new Error(i18n.t('errorLog.passwordMismatch')));
  } else {
    callback();
  }
};
export const validatePassword = (rule, value, callback) => {
  if (value.length < 8) {
    callback(new Error(i18n.t('errorLog.passwordLimitLength')));
  } else {
    callback();
  }
};

export const validateLink = (rule, value, callback) => {
  // Regular expression to validate URL
  const validLinkPattern = /^https?:\/\/[\w.-]+\.[a-zA-Z]{2,6}(\/[\w.,@?^=%&:;/~+#-]*)?$/;

  if (!validLinkPattern.test(value)) {
    callback(new Error(i18n.t('staff.form.rule.valid_link')));
  } else {
    callback();
  }
};

export const checkCode = (rule, value, callback, errorMessage) => {
  if (errorMessage) {
    callback(errorMessage);
  }
  callback();
};

export const checkExits = (rule, value, callback, errorMessage) => {
  if (errorMessage) {
    callback(errorMessage);
  }
  callback();
};

export const validateUsedLeave = (rule, value, callback, total) => {
  console.log(total, value);
  if (value > total || value < 0) {
    callback(new Error(i18n.t('errorLog.usedLeaveLimit')));
  } else {
    callback();
  }
};
