import ProjectApi from '@/api/ProjectApi';
import BaseStore from './BaseStore';

const BaseProjectStore = new BaseStore(ProjectApi);

const state = {
  project_response: [],
};

const getters = {
  projectResponse: (state) => state.project_response,
};

const mutations = {
  SET_PROJECT_RESPONSE(state, payload) {
    state.project_response = payload;
  },
};

const actions = {
  ...BaseProjectStore.actions,
  async fetchProjects({ commit }, listQuery) {
    const response = await ProjectApi.get(listQuery);
    commit('SET_PROJECT_RESPONSE', response);
  },

  async getSelfProjects() {
    return await ProjectApi.getSelfProjects();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
