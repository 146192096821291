import router from './router';
import store from './store';
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import getPageTitle from '@/utils/get-page-title';
import { isLogged } from '@/utils/auth';

NProgress.configure({ showSpinner: false }); // NProgress Configuration
const errorRoutes = ['/401', '/404'];
const whiteList = [
  '/login',
  '/auth-redirect',
  '/forgot-password',
  '/renew-password',
  '/auth/google',
]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  // set page title
  document.title = getPageTitle(to.meta.title);
  const toPathIsInWhiteList = whiteList.indexOf(to.path) !== -1;
  const toPathIsErrorPage = errorRoutes.includes(to.path);
  if (isLogged() && to.path === '/login') {
    next('/');
    return;
  }
  if (toPathIsInWhiteList || toPathIsErrorPage) {
    next();
    return;
  }
  if (!isLogged()) {
    next(`/login?redirect=${to.path}`);
    return;
  }
  const hasRoles = store.getters.roles && store.getters.roles.length > 0;
  if (hasRoles) {
    next();
    return;
  }
  try {
    // get user info
    // note: roles must be a object array! such as: ['admin'] or ,['manager','editor']
    const { roles } = await store.dispatch('UserStore/getFullInfo');
    const permissions = store.getters.permissions;
    if (!roles.length) {
      await store.dispatch('AuthStore/logout');
      next({ path: '/401' });
      return;
    }
    // generate accessle routes map based on roles
    const accessRoutes = await store.dispatch('permission/generateRoutes', {
      roles,
      permissions,
    });
    router.addRoutes(accessRoutes);
    next({ ...to, replace: true });
    return;
  } catch (error) {
    // remove token and go to login page to re-login
    Message.error(error.message || 'Has Error');
  }
  await store.dispatch('AuthStore/logout');
  next(`/login?redirect=${to.path}`);
  NProgress.done();
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
