import Layout from '@/layout';

const dashboardRoutes = {
  path: '/dashboard',
  component: Layout,
  redirect: '',
  children: [
    {
      path: '',
      component: () => import('@/views/dashboard'),
      name: 'Dashboard',
      meta: {
        title: 'dashboard',
        icon: 'dashboard',
        noCache: true,
        permissions: ['manage dashboard'],
      },
    },
  ],
};

export default dashboardRoutes;
