export default class {
  constructor(apiClass) {
    this.actions = {
      async get(store, param = []) {
        const response = await apiClass.get(param);
        return response || [];
      },

      async find(store, id) {
        const response = await apiClass.find(id);
        return response || null;
      },

      async store(store, data) {
        const response = await apiClass.store(data);
        return response || false;
      },

      async update(store, { id, data }) {
        const response = await apiClass.update(id, data);
        return response || false;
      },

      async destroy(store, id) {
        const response = await apiClass.destroy(id);
        return response || false;
      },
    };
  }
}
