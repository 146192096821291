import BaseApi from './BaseApi';

const baseUrl = 'curriculum-vitaes';

class CurriculumVitaeApi extends BaseApi {
  getSelfCV() {
    return this.axios.get(this.baseUrl + '/get-self-cv').catch((error) => {
      throw new Error(error);
    });
  }

  updateSelfCV(data) {
    return this.axios.post(this.baseUrl + '/update-self-cv', data).catch((error) => {
      throw new Error(error);
    });
  }
}

export default new CurriculumVitaeApi(baseUrl);
