<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  watch: {
    language() {
      this.$i18n.locale = this.language;
    },
  },
};
</script>
