import SkillApi, { categorySkillApi, optionSkillApi } from '@/api/SkillApi';
import BaseStore from './BaseStore';

const BaseSkillStore = new BaseStore(SkillApi);

const state = {
  skill_response: [],
  skill_options: [],
  skill_category: [],
};

const getters = {
  skillResponse: (state) => state.skill_response,
  skillOptions: (state) => state.skill_options,
  skillCategory: (state) => state.skill_category,
};

const mutations = {
  SET_SKILL_RESPONSE(state, payload) {
    state.skill_response = payload;
  },
  SET_SKILL_OPTIONS(state, payload) {
    state.skill_options = payload;
  },
  SET_SKILL_CATEGORY(state, payload) {
    state.skill_category = payload;
  },
};

const actions = {
  ...BaseSkillStore.actions,
  async fetchSkills({ commit }, listQuery = {}) {
    const response = await SkillApi.get(listQuery);
    if (response) {
      commit('SET_SKILL_RESPONSE', response);
      return response;
    }
    return false;
  },

  async addSkill(_, data) {
    const res = await SkillApi.store(data);
    return res;
  },

  async fetchSkillOptions({ commit }, listQuery = {}) {
    const response = await optionSkillApi.get(listQuery);
    if (response) {
      commit('SET_SKILL_OPTIONS', response);
    }
    return response;
  },
  async fetchSkillCategory({ commit }, listQuery = {}) {
    const response = await categorySkillApi.get(listQuery);
    if (response) {
      commit('SET_SKILL_CATEGORY', response);
    }
    return response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
