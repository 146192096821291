import PermissionApi from '@/api/PermissionApi';
import BaseStore from './BaseStore';

const BasePermissionStore = new BaseStore(PermissionApi);

const state = {
  permission: [],
};

const getters = {
  permission: (state) => state.permission,
};

const mutations = {
  SET_PERMISSION(state, payload) {
    state.permission = payload;
  },
};

const actions = {
  ...BasePermissionStore.actions,
  async fetchPermissions({ commit }, listQuery) {
    const response = await PermissionApi.get(listQuery);
    commit('SET_PERMISSION', response);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
