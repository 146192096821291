import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from '@/layout';
import store from '../store';

/* Router for modules */
import adminRoutes from './modules/admin';
import staffRoutes from './modules/staff';
import dashboardRoutes from './modules/dashboard';
import permissionRoutes from './modules/permission';

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: '/auth/google',
    component: () => import('@/views/auth/google/index'),
    hidden: true,
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/AuthRedirect'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
  },
  {
    path: '',
    component: Layout,
    beforeEnter(to, from, next) {
      if (store.getters.roles[0] === 'admin') {
        next('/dashboard');
      } else {
        next('/staff/home');
      }
    },
  },
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/edit',
    children: [
      {
        path: 'edit',
        component: () => import('@/views/users/SelfProfile'),
        name: 'SelfProfile',
        meta: { title: 'userProfile', icon: 'user', noCache: true },
      },
    ],
    hidden: true,
  },
  {
    path: '/renew-password',
    component: () => import('@/views/RenewPassword'),
    name: 'RenewPassword',
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/ForgotPassword'),
    name: 'ForgotPassword',
  },
];

export const asyncRoutes = [
  permissionRoutes,
  dashboardRoutes,
  staffRoutes,
  adminRoutes,
  { path: '*', redirect: '/404', hidden: true },
];

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
