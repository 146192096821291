import AuthApi from '@/api/AuthApi';
import { setToken, removeToken } from '@/utils/auth';
// import { resetRouter } from '@/router';

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
  async login({ dispatch }, data) {
    const res = await AuthApi.login(data);
    const { token } = res;
    if (token) {
      setToken(token);
    }
    return res;
  },

  async forgotPassword(context, data) {
    return await AuthApi.forgotPassword(data);
  },

  async verifyExpired(context, data) {
    return await AuthApi.verifyExpired(data);
  },

  async renewPassword(context, data) {
    return await AuthApi.renewPassword(data);
  },

  async logout() {
    await removeToken();
    // resetRouter();
  },
  async getGoogleSignInUrl() {
    return await AuthApi.getGoogleSignInUrl();
  },
  async googleLogin(context, queryString) {
    const res = await AuthApi.googleLogin(queryString);
    const { token } = res;
    if (token) {
      setToken(token);
    }
    return res;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
