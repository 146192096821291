import PropertyApi from '@/api/PropertyApi';
import BaseStore from './BaseStore';

const BasePropertyStore = new BaseStore(PropertyApi);

const state = {
  property_response: [],
  property_group: [],
  property_status: [],
};

const getters = {
  propertyResponse: (state) => state.property_response,
  propertyGroup: (state) => state.property_group,
  propertyStatus: (state) => state.property_status,
};

const mutations = {
  SET_PROPERTY_RESPONSE(state, payload) {
    state.property_response = payload;
  },
  SET_PROPERTY_GROUP(state, payload) {
    state.property_group = payload;
  },
  SET_PROPERTY_STATUS(state, payload) {
    state.property_status = payload;
  },
};

const actions = {
  ...BasePropertyStore.actions,
  async fetchProperties(context, listQuery) {
    const response = await PropertyApi.get(listQuery);
    if (response) {
      context.commit('SET_PROPERTY_RESPONSE', response);
    }
  },
  async fetchPropertyGroup({ commit }) {
    const response = await PropertyApi.getPropertyGroup();
    if (response) {
      commit('SET_PROPERTY_GROUP', response);
    }
  },
  async fetchPropertyStatus({ commit }) {
    const response = await PropertyApi.getPropertyStatus();
    if (response) {
      commit('SET_PROPERTY_STATUS', response);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
